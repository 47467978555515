.openedDrawer {
  width: 20rem;
  transition: all 0.1s ease;
  height: 100%;
  left: 0;
  position: fixed;
  overflow-x: hidden;
  z-index: 1;
}

@media (max-width: 660px) {
  .openedDrawer {
    height: 100%;
    width: 100%;
    z-index: 1;
    left: 0;
    overflow-x: hidden;
    position: fixed;
  }
}

.closedDrawer {
  width: 0;
  height: 0;
  visibility: hidden;
  opacity: 0;
}

.spaceQuoteGroup {
  height: 40rem;
  overflow: auto;
}