.logo {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 2;
  width: 100%;
  background-color: white;
  padding-bottom: 3rem;
}

.children {
  margin-top: 5rem;
}

.icon {
  color: var(--main-item-color);
  font-size: 3rem;
}

.headerTop {
  font-weight: bold;
  margin: 0;
}

.headerBottom {
  color: var(--passive-color);
  margin: 0;
}

.menu {
  color: var(--main-item-color);
}

.close {
  color: var(--close-button-color);
}

.close:hover {
  cursor: pointer;
}

.burger {
  position: absolute;
  right: 1rem;
}

.burger:hover {
  cursor: pointer;
}