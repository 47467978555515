.menuItem {
  display: flex;
  color: var(--passive-color);
  align-items: center;
  border-radius: 0.5rem;
  padding: 0.5rem 0.7rem;
}

.navStyle {
  text-decoration: none;
}

.input {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0.5rem 0.7rem;
  border-radius: 0.5rem;
  background-color: var(--main-bg-color);
}

.inputField {
  display: flex;
  flex-wrap: wrap;
  padding: 0.25rem 0.5rem;
  border-radius: 0.5rem;
  border: none;
}

.inputField:focus{
  outline-color: var(--main-item-color)
}

.icon {
  margin-right: 1rem;
  color: var(--main-item-color);
  font-size: 1rem;
}

.moreIcon {
  font-size: 1rem;
  margin-left: auto;
  font-weight: bold;
  color: var(--main-item-color);
}

.deleteIcon {
  font-size: 1rem;
  margin: 0 0 auto 0;
  color: var(--close-button-color);
}

.editIcon {
  font-size: 1rem;
  margin: 0 0 0 auto;
  color: var(--main-item-color);
}

.doneButton {
  margin: 0 0 0 auto;
  color: var(--main-item-color);
}

.closeButton {
  margin: 0 0 0 0;
  color: var(--close-button-color);
}

.doneButton:hover {
  cursor: pointer;
}

.closeButton:hover {
  cursor: pointer;
}

.activeTitle {
  color: black;
}

.menuItem:hover {
  background-color: var(--main-bg-color);
  cursor: pointer;
}

.activeItem {
  background-color: var(--main-bg-color);
}
