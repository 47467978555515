:root{
  --main-bg-color: #EEF7FF;
  --passive-color: #787878;
  --subtract-button-color: #94B6D1;
  --close-button-color: #D2042D;
  --main-item-color: #008DFF;
}

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: Inter, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 0.2rem;
  height: 0.2rem;
}

::-webkit-scrollbar-thumb {
  background-color: var(--main-item-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.material-symbols-rounded {
  font-variation-settings:
  'FILL' 1,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48
}
