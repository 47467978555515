.card {
  border-radius: 0.5rem;
  background-color: var(--main-bg-color);
  padding: 0.25rem 1rem;
}

.title {
  font-size: 1rem;
  font-weight: 400;
}

.tip {
  color: var(--main-item-color);
}
