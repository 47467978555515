.menuHeader {
  margin: 1rem 0 0 0;
  display: flex;
  align-items: center;
}

.navStyle {
  text-decoration: none;
}

.divider {
  margin-top: 1rem;
  border-top: 1px solid var(--main-bg-color);
}

.card {
  border-radius: 0.5rem;
  align-items: center;
  background-color: var(--main-bg-color);
  padding: 0.25rem 1rem;
  margin: 1rem;
}

.tip {
  color: var(--main-item-color);
}

.addButton {
  margin-left: auto;
  color: var(--main-item-color);
}

.addButton:hover {
  cursor: pointer;
}

.input {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.7rem;
  border-radius: 0.5rem;
  background-color: var(--main-bg-color);
}

.inputField {
  padding: 0.25rem 0.5rem;
  border-radius: 0.5rem;
  border: none;
}

.inputField:focus {
  outline-color: var(--main-item-color);
}

.activeItem {
  background-color: var(--main-bg-color);
}

.icon {
  margin-right: 1rem;
  color: var(--main-item-color);
  font-size: 1rem;
}

.doneButton {
  margin: 0 0 0 auto;
  color: var(--main-item-color);
}

.closeButton {
  margin-left: 0 0 0 auto;
  color: var(--close-button-color);
}

.doneButton:hover {
  cursor: pointer;
}

.closeButton:hover {
  cursor: pointer;
}

.menuHeader p {
  color: var(--passive-color);
}

.menuItemGroup {
  max-height: 10rem;
  overflow: auto;
}
