.logo {
  display: flex;
  align-items: center;
  top: 0;
  width: 100%;
  background-color: white;
}

.loginLogo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.loginLogo p {
  font-size: 1.75rem;
}

.loginLogo span {
  font-size: 4rem;
}

.icon {
  color: var(--main-item-color);
  font-size: 3rem;
}

.headerTop {
  font-weight: bold;
  margin: 0;
}

.headerBottom {
  color: var(--passive-color);
  margin: 0;
}

.login {
  margin-top: 5rem;
  justify-content: center;
  display: flex;
}

.loginCard {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 0.5rem;
  width: 30rem;
  padding: 2.25rem;
}

@media (max-width: 500px) {
  .loginCard {
    width: 100%;
    height:100%;
    box-shadow: none;
  }

  .logo {
    display: none;
  }

  .toggleSignIn a, p{
    font-size: 0.9rem;
  }

  .forwardIcon {
    font-size: 1rem;
  }

}


.loginHeader {
  margin-top: 0.7rem;
  display: flex;
  justify-content: center;
  font-family: 'Space Grotesk', sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--main-item-color);
}

.inputs {
  display: grid;
  gap: 0.5rem;
  margin: 1rem 0 0;
  font-size: 1.25rem;
}

.inputs label {
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
}

.done {
  color: var(--main-item-color);
  font-size: 1.25rem;
  margin-left: 0.25rem;
}

.close {
  color: var(--close-button-color);
  font-size: 1.25rem;
  margin-left: 0.25rem;
}

.info {
  font-size: 1rem;
}

.validationInfo {
  font-size: 0.75rem;
  border-radius: 0.5rem;
  background: var(--main-item-color);
  color: #fff;
  padding: 0.25rem;
  position: relative;
}

.labels {
  display: flex;
  align-items: center;
}

.inputs input {
  width: 100%;
  padding: 0.375rem 0.75rem;
  border-radius: 0.5rem;
  line-height: 1.5;
  font-size: 1rem;
  font-weight: 400;
  border: 1px solid #ced4da;
  appearance: none;
}

.inputs input:focus {
  outline-color: var(--main-item-color);
}

.submit {
  margin-top: 1.5rem;
  text-align: center;
  align-items: center;
}

.btn {
  font-size: 1.25rem;
  font-weight: 700;
  border-radius: 0.5rem;
  padding: 1rem;
  color: white;
  background-color: var(--main-item-color);
  border: none;
}


.disabledBtn{
  font-size: 1.25rem;
  font-weight: 700;
  border-radius: 0.5rem;
  padding: 1rem;
  border: none;
}

.btn:hover {
  cursor: pointer;
}

.toggleSignIn {
  display: flex;
  margin-top: 1rem;
  font-size: 1.25rem;
}

.toggleSignIn a {
  text-decoration: none;
  display: flex;
  align-items: center;
  color: var(--main-item-color);
  padding: 0 0.25rem;
}

.forwardIcon {
  font-size: 1.25rem;
}

.invalid {
  color: red;
}

