.footer {
  position: fixed;
  bottom: 0;
  z-index: 2;
  background-color: white;
  width: 100%;
}

.content {
  display: flex;
  align-items: center;
  padding: 0 0 0 0.7rem;
}

.avatar {
  vertical-align: middle;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin: 0 1rem 0 0;
}

.content span {
  color: var(--main-item-color);
  margin: 0 1rem 0 0;
}

.logoutButton {
  font-size: 2rem;
}

.logout:hover {
  cursor: pointer;
}

.logout p:hover{
  color: var(--main-item-color);
  transition: 0.2s ease;
}